<template>
    <list-page
        page-title="Bill Definition"
        page-icon="mdi-account-multiple-outline"
        collection-name="billDefinition"
        :items-per-page="20"
        add-dialog-title="Add Bill Definition"
        is-filter
    />
</template>

<script>
import ListPage from '@/components/pages/List';


export default {
    name: 'BillDefinitionList',
    components: { ListPage },

};
</script>

<style scoped></style>
